@use "../../scss/" as *;

.loginPage{
    width: 100%;
    height: 100vh;
    background-color: $gray-100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .loginHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 446rem;
        grid-gap: 8rem;
        margin-bottom: 36rem;
        p{
            text-align: center;
            @include fnt(16, 20, $weight: 500, $font: $fontText, $color: $gray-800)
        }
    }

    .loginFooter{
        display: flex;
        align-items: center;
        grid-gap: 6rem;
        margin-top: 32rem;
        .indicatorText{
            @include fnt(14, 20, $weight: 500, $font: $fontUI, $color: $gray-400)
        }
    }
}