@use "../../scss" as *;

.checkbox{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  border-radius: 4rem;


  .checkboxMark{
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
  }
}

.checkFlex{
  display: flex;
  border-radius: 8rem;
  align-items: center;
     
  &.INVENTORY{
    background-color: rgba(34, 204, 238, 0.20);
  }
  &.FOR_SALE{
    background-color: rgba(102, 198, 28, 0.20);
   
  }
  &.PRIMARY_RESERVATION{
    background-color: rgba(234, 170, 8, 0.20);
  }
  &.RESERVATION{
    background-color: rgba(239, 104, 32, 0.20);
  }
  &.DEAL{
    background-color: rgba(112, 112, 123, 0.20);
  }
}

.checkbox-container {
    display: flex;
    align-items: center;
 
}

.checkbox-input {
    appearance: none;
    width: 16rem;
    height: 16rem;
    border: 2rem solid $gray-400;
    border-radius: 5rem;
    cursor: pointer;
    transition: border-color 0.3s linear;
    position: relative;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &.INVENTORY{
      border-color: #2CE;
    }
    &.FOR_SALE{
      border-color: #66C61C;
     
    }
    &.PRIMARY_RESERVATION{
      border-color: #EAAA08;
    }
    &.RESERVATION{
      border-color: #EF6820;
    }
    &.DEAL{
      border-color: #70707B;
    }
}

.checkbox-input:checked {
    border-color: $blue;
    background-color:$blue;

    &.INVENTORY{
      border-color: #2CE;
      background-color: #2CE;
    }
    &.FOR_SALE{
      border-color: #66C61C;
      background-color: #66C61C;
     
    }
    &.PRIMARY_RESERVATION{
      border-color: #EAAA08;
      background-color: #EAAA08;
    }
    &.RESERVATION{
      border-color: #EF6820;
      background-color: #EF6820;
    }
    &.DEAL{
      border-color: #70707B;
      background-color: #70707B;
    }
}

/* Стили для галочки */


.checkbox-input:checked + .checkboxMark{
    opacity: 1; 
  }

.checkbox-label {
    cursor: pointer;
    margin-left: 6rem;
    @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $txt-color)
}

.checkboxCount{
  margin: 0 6rem;
  @include fnt(14, 16, $weight: 400, $font: $fontText, $color: rgba(44, 45, 46, 0.50))
}

// .checkbox-label.checked {
//     color:$blue;
// }

