.inputWithReset {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    width: 85%; // ширина 85% для компенсации кнопки
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px; // скругление углов слева
    font-size: 16px;
    outline: none;

    &.inputError {
      border-color: red;
    }
  }

  .dropdown {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 85%; // ширина совпадает с инпутом
    max-height: 448px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;

    li {
      padding: 8px 12px;
      cursor: pointer;
      list-style: none;
      font-size: 16px;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .resetButton {
    width: 15%; // ширина 15% для слева стороны инпут
    padding: 8px 12px;
    background-color: #ff4d4f;
    color: #fff;
    border: 1px solid #ff4d4f;
    border-radius: 0 4px 4px 0; // скругление углов справа
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff7875;
    }
  }
}