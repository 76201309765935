@use "../../scss" as *;

.uploadFileContainer {
  padding: 16rem;
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 8rem;

  input[type="file"] {
    display: none;
  }

  .fileInputLabel {
    flex-direction: row-reverse;
    grid-gap: 4rem;
    background-color: $white;
    border: 1rem solid $gray-300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8rem;
    border-radius: 8rem;
    cursor: pointer;
    @include fnt(14, 20, $weight: 500);

    &:hover {
      background-color: var(--blue-dark-100, #bbdefb);
    }
    object{
      margin-right: 0;
    }
  }

  .uploadButton {
    flex-direction: row-reverse;
    grid-gap: 4rem;
    background-color: $white;
    border: 1rem solid $gray-300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8rem;
    border-radius: 8rem;
    cursor: pointer;

    @include fnt(14, 20, $weight: 500, $font: $fontText, $color: $gray-700);
    object{
      margin-right: 0;
    }
  }

  .error-message {
    color: var(--redButton, #d92d20);
    @include fnt(14, 20, $weight: 500);
  }

  .success-message {
    color: var(--green, #388e3c);
    @include fnt(14, 20, $weight: 500);
  }
}