@use "../../scss" as *;

.tabRole{
    width: 100%;
    .roleContainerButton{
        display: flex;
        background-color: $gray-50;
        padding: 4rem 0;
        border-radius: 8rem;
        height: 36rem;
        border: 1px solid $gray-200;

        .buttonRole{
            margin: 0 4rem;
            background-color: $gray-50;
            width: 100%;
            @include fnt(14, $color: $txt-color, $weight: 500, $height: 20)
        }
        .active{
            background-color: $white;
            color: $gray-700;
            font-weight: 500;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        }
    }
}