@use "../../scss/" as *;


.lifeCituationContainer{
    margin-top: 16rem;
    display: flex;
    grid-gap: 4rem;
    flex-wrap: wrap;
    width: 100%;

    .lifeCituation{
        display: flex;
        grid-gap: 4rem;
        width: 100%;
    }

    .lifeContinaerItem{
        display: flex;
       grid-gap: 4rem;

        &.active{
            width: 100%;
        }
    }

    
}

.cardLifeSituation{
    background-color: $gray-100;
    border-radius: 12rem;
    padding: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 160rem;
    cursor: pointer;
    min-height: 160rem;
    transition: .3s linear;

    .cardHeader{
        height: 100%;
    }

    &:hover{
        background-color: $gray-200;
    }

    .cardHeaderSubtitle{
        @include fnt(12, 16, $weight: 400, $color: $gray-500, $font: $fontUI);
    }
    .cardHeaderTitle{
        @include fnt(14, 18, $weight: 500, $color: $gray-800, $font: $fontUI)
    }
    .cardFooterNumber{
        @include fnt(14, 16, $weight: 400, $color: $gray-500, $font: $fontUI)
    }
   
   
}

.cardHeaderTitleType{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cardFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: all;

    .footerRight{
        display: flex;
        flex-direction: column;
        grid-gap: 4rem;
    }
    .containerButtonCard{
        display: flex;
        gap: 4rem;
        align-items: center;
    }
}

.footerFlex{
    display: flex;
    justify-content: space-between;
}

.whiteIco{
    padding: 4rem !important;
    background-color: $white !important;
    transition: .3s ease-in-out !important;
    border-radius: 8rem !important;

    &:hover{
        background-color: $wg-gray !important;
    }

    object{
        width: 16rem;
        height: 16rem;
        margin: 0;
    }
    
}

.lifeSituationServiceContainer{
    display: flex;
    flex-direction: column;
    grid-gap: 12rem;

    .whiteButtonAdd{
        padding: 4rem;
        border-radius: 12rem;
        background-color: $white;
        border: 1rem solid $blue-dark-200;
        justify-content: start;
        width: 246rem;
        height: 24rem;
        @include fnt(12, 16, $weight: 400, $color: $blue-dark-500, $font: $fontUI);

        object{
            width: 16rem;
            height: 16rem;
        }
    }


    &.flexGrid{
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 12rem;
    }
    
}

.cardLifeSituationServices{
    background-color: $gray-100;
    border-radius: 12rem;
    padding: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360rem;
    min-height: 160rem;
    cursor: pointer;
    transition: .3s ease-in-out;

    .cardHeader{
        height: 100%;
    }
}

.containerServiceProcesses{
    display: flex;
    grid-gap: 4rem;
}

.modalServiceFile{
    display: flex;
    flex-direction: column;
    gap: 16rem;
}

.containerServiceFile{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerCheckFile{
    display: flex;
    align-items: center;
    gap: 4rem;
    padding-left: 0;

    &.active{
        padding-left: 24rem;
    }
}

.p-fileupload{
    width: fit-content;
    max-width: 105rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8rem;
    border: 1rem solid $gray-300;
    padding: 4rem;
    gap: 4rem;

    .p-button{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4rem;
    }
}

.toast{
    width: 400rem;
    background-color: $green-400;
    position: fixed;
    top: 0;
    right: 0;

   
}
.p-toast-message-content{
    width: 200rem;
    
    display: flex;
    gap: 12rem;
}
.p-toast-message{
    width: 200rem;
    background-color: $green-400;
    padding: 12rem;
    border-radius: 4rem;
    svg{
        display: none;
    }

    .p-toast-summary{
        @include fnt(12rem, $color: $white)
    }
    .p-toast-detail{
        @include fnt(12rem, $color: $white)
    }
   
}

.myButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 8rem;
    background-color: #ffffff;
    border: none;
    border-radius: 8rem;
    cursor: pointer;

    @include fnt(14, 20, $weight: 500, $font: $fontText, $color: $gray-700);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .iconContainer {
        margin-right: 0;
    }

    &:hover {
        background-color: $gray-100;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2rem rgba(41, 121, 255, 0.5);
    }
}
