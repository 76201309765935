@use "../../scss/" as *;


// @keyframes loginAnimation {
//     0% {
//         transform: scale(1);
//         width: 400rem;
//         height: 400rem;
//         background-color: $white;
//         border-radius: 32rem;
//     }
//    100% {
       
//         transform: scale(0.6);
//         width: 30rem;
//         height: 30rem;
//         background-color: $blue;
//         border-radius: 50%;
//     }
// }
// @keyframes loginInputAnimation {
//     0% {
//         display: none;
//         opacity: 0;
//     }
//     100%{
//         display: flex;
//         opacity: 0;
//     }
// }


.loginForm{
    width: 400rem;
    height: 400rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginFormContainer {
    background-color: $white;
    padding: 40rem 48rem 48rem;
    border-radius: 32rem;
    width: 400rem;
    height: 380rem;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    transition: .2s ease-in-out;
     
    // transform: scale(1);

    &.miniContainer{
        height: 300rem;
    }

    // &.active {
    //     animation: loginAnimation 1s ease-in-out;

    //     .containerInput {
    //         opacity: 0;
    //         transition: .3s ease-out;
    //         animation: loginInputAnimation 1s linear;

    //     }
    //     transform: scale(.6);
    //     width: 30rem;
    //     height: 30rem;
    //     background-color: $blue;
    //     border-radius: 50%;
    // }

    .containerInput {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        grid-gap: 16rem;
        transition: .4s ease-in-out;
        opacity: 1;

        .containerCheck {
            display: flex;
            justify-content: center;
            align-items: center;
    
            p {
                cursor: pointer;
                @include fnt(14, 20, $weight: 600, $font: $fontText, $color: $blue-dark-500);
            }
        }
    }

    h1 {
        @include fnt(24, 32, $weight: 600, $font: $fontText, $color: $gray-800);
    }

    

    .buttonLogin {
        padding: 12rem;
        border-radius: 8rem;
    }

    .backButton{
        cursor: pointer;
        text-align: center;
        @include fnt(16, 20, $weight: 400, $font: $fontText, $color: $gray-400);
    }
}
