@use "../../scss" as *;

.reestrComponent {
  padding: 0 16rem;
  width: calc(100vw - 8rem);

  .reestrNav {
    display: grid;
    align-items: center;
    grid-template-columns: 192rem 150rem 500rem 1fr 244rem;
    grid-gap: 8rem;
    margin-bottom: 16rem;

    .whiteButton {
      background-color: $white;
      border: 1rem solid var(--blue-dark-200, #B2CCFF);
      border-radius: 8rem;
      height: 36rem;
      @include fnt(14, 20, $color: $blue-dark-500, $weight: 500)
    }

    .redButton {
      background-color: $white;
      border: 1rem solid var(--redButton, #d92d20);
      border-radius: 8rem;
      height: 36rem;
      @include fnt(14, 20, $color: $red, $weight: 500)
    }
  }

}

.formMini {
  .optionsContainer {
    max-height: 130rem;
  }
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
  grid-gap: 8rem;
  margin-top: 24rem;

  .buttonModal {
    width: 50%;
    padding: 12rem;
    border-radius: 8rem;

    &_white {
      width: 50%;
      padding: 12rem;
      border-radius: 8rem;
      background-color: $white;
      border: 1rem solid $gray-300;
      color: $gray-700;
    }
  }
}

.inputSearchContainer {
  width: 100%;
  position: relative;

  .buttonSearch {
    position: absolute;
    right: 4rem;
    top: 50%;
    transform: translateY(-50%);
    height: 28rem;
    width: 96rem;
    @include fnt(14, 20, $weight: 500, $color: $white)
  }

  .searchInput {

    width: 100%;
    height: 36rem;
    border: 1rem solid rgba(0, 16, 61, 0.12);
    border-radius: 4rem;
    font-size: 14rem;
    padding: 8rem 35rem 8rem 12rem;
    outline: none;
    transition: 0.2s linear;


    .p {
      font-style: normal;
      font-weight: 400;
      font-size: 12rem;
      line-height: 16rem;
      color: $redButton;
      margin-left: 10rem;
      margin-top: 4rem;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .button {
    flex-direction: row-reverse;
    grid-gap: 4rem;
    background-color: $white;
    border: 1rem solid $gray-300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8rem;
    border-radius: 8rem;
    cursor: pointer;
    margin: 8rem;

    &:disabled {
      background-color: $gray-200;
      color: $gray-500;
      border-color: $gray-300;
      cursor: not-allowed;
      box-shadow: none;
    }

    @include fnt(14, 20, $weight: 500, $font: $fontText, $color: $gray-700);

    object {
      margin-right: 0;
    }
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
  color: #666;
}
